:root {
  --blue: #76f0fc;
  --selection: #b3d4fc;
  --green: #b6f764;
  --grey: #6e7889;
  --lightgrey: #eaf0fa;
  --purple: #c976e7;
  --yellow: #f7e652;
}

code,
pre {
  color: var(--lightgrey);
  background: none;
  text-align: left;
  white-space: pre;
  line-height: 1.9;

  -moz-tab-size: 2;
  -o-tab-size: 2;
  tab-size: 2;

  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}

pre[class*="language-"]::-moz-selection,
pre[class*="language-"] ::-moz-selection,
code[class*="language-"]::-moz-selection,
code[class*="language-"] ::-moz-selection {
  background: var(--selection);
}

pre[class*="language-"]::selection,
pre[class*="language-"] ::selection,
code[class*="language-"]::selection,
code[class*="language-"] ::selection {
  background: var(--selection);
}

/* Code blocks */
pre[class*="language-"] {
  padding: 1em;
  margin: 0.5em 0;
  overflow: auto;
}

/* Inline code */
:not(pre) > code[class*="language-"] {
  padding: 2px 4px;
  border-radius: 3px;
  white-space: normal;
}

.token.comment,
.token.prolog,
.token.doctype,
.token.cdata,
.token.operator,
.token.punctuation {
  color: var(--grey);
}

.token.namespace {
  opacity: 0.7;
}

.token.property,
.token.tag,
.token.constant,
.token.symbol,
.token.deleted {
  color: var(--blue);
}

.token.number,
.token.boolean {
  color: var(--purple);
}

.token.selector,
.token.attr-name,
.token.string,
.token.char,
.token.builtin,
.token.inserted {
  color: var(--green);
}

.token.atrule,
.token.attr-value,
.token.keyword {
  color: var(--yellow);
}

/* From here on, these have not been designed, as we handle JSON mostly, but I’ve added
 * some defaults that will look better than leaving the PrismJS defaults */

.token.entity,
.token.url,
.language-css .token.string,
.style .token.string {
  color: var(--purple);
}

.token.function,
.token.class-name {
  color: var(--yellow);
}

.token.regex,
.token.important,
.token.variable {
  color: var(--blue);
}

.token.important,
.token.bold {
  font-weight: bold;
}

.token.italic {
  font-style: italic;
}

.token.entity {
  cursor: help;
}
