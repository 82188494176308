form[action="https://api.feedback.fish/feedback"] {
  border-radius: 5px;
  min-height: 190px;
}

form[action="https://api.feedback.fish/feedback"] h1 {
  font-weight: 500;
}

form[action="https://api.feedback.fish/feedback"] input + div:last-child {
  visibility: hidden;
  height: 0;
}

form[action="https://api.feedback.fish/feedback"] textarea:focus {
  box-shadow: none;
  border-color: #3881ff;
}
